@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px _palette(border);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;
			margin-top: -0.675em;

			li {
				display: inline-block;
				padding: 0.675em 0.5em;

				a {
					@include icon-alt(false, true);
					position: relative;
					display: block;
					width: 3.75em;
					height: 3.75em;
					border-radius: 100%;
					border: solid _size(border-width) _palette(border);
					line-height: 3.75em;
					overflow: hidden;
					text-align: center;
					text-indent: 3.75em;
					white-space: nowrap;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
						font-size: 1.85rem;
						line-height: inherit;
						text-align: center;
						text-indent: 0;
					}

					&:hover {
						border-color: _palette(highlight);
					}
				}
			}

			@include breakpoint(xsmall) {
				li {
					a {
						&:before {
							font-size: 1.5rem;
						}
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.5) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}