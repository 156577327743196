.links {
  padding: 0;
  font-size: .9em;
  list-style: none;
  display: flex;
  align-content: stretch;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -0.5em;
}

.links-item {
  line-height: 2;
  padding: 0;
  flex-basis: auto;
  margin: 0 0.5em;
}

.links--horizontal {
  flex-direction: row;
}