@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'fontawesome';
@import 'brands';
@import 'solid';
@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:300');

// Identity by HTML5 UP
// html5up.net | @n33co
// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)

@include skel-breakpoints((
  xlarge: '(max-width: 1680px)',
  large: '(max-width: 1280px)',
  medium: '(max-width: 960px)',
  small: '(max-width: 736px)',
  xsmall: '(max-width: 480px)',
  xxsmall: '(max-width: 360px)'
));

@include skel-layout((
  reset: 'full',
  boxModel: 'border'
));

@mixin icon-alt($content: false, $link: false) {
  @include icon($content);
  $size: _size(border-width) * 1.25;

  &:before {
    @include var(color, --bg, _palette(bg));
    text-shadow: $size 0px 0px _palette(border),
    ($size * -1) 0px 0px _palette(border),
    0px $size 0px _palette(border),
    0px ($size * -1) 0px _palette(border);

  }

  @if $link {
    &:hover {
      &:before {
        text-shadow: $size 0px 0px _palette(highlight),
        ($size * -1) 0px 0px _palette(highlight),
        0px $size 0px _palette(highlight),
        0px ($size * -1) 0px _palette(highlight);
      }
    }
  }
}

:root {
  --bg: #{_palette(bg)};
  --fg: #{_palette(fg)};
  --fg-bold: #{_palette(fg-bold)};
  --fg-light: #{_palette(fg-light)};
  --link: #{_palette(link)};
  --link-rgb: #{_palette(link-rgb)};
  --border: #{_palette(border)};
  --highlight: #{_palette(highlight)};
  --highlight-rgb: #{_palette(highlight-rgb)};
  --accent1: #{_palette(accent1)};
  --accent2: #{_palette(accent2)};
  --accent1-transparent: #{_palette(accent1-transparent)};
  --accent2-transparent: #{_palette(accent2-transparent)};
}

@media(prefers-color-scheme: dark) {
  :root {
    --bg: #{_palette-dark(bg)};
    --fg: #{_palette-dark(fg)};
    --fg-bold: #{_palette-dark(fg-bold)};
    --fg-light: #{_palette-dark(fg-light)};
    --link: #{_palette-dark(link)};
    --link-rgb: #{_palette-dark(link-rgb)};
    --border: #{_palette-dark(border)};
    --highlight: #{_palette-dark(highlight)};
    --highlight-rgb: #{_palette-dark(highlight-rgb)};
    --accent1: #{_palette-dark(accent1)};
    --accent2: #{_palette-dark(accent2)};
    --accent1-transparent: #{_palette-dark(accent1-transparent)};
    --accent2-transparent: #{_palette-dark(accent2-transparent)};
  }
}

// Base.

@import 'base/page';
@import 'base/typography';
@import 'base/utility';

// Component.

// @import 'components/form';
@import 'components/icon';
@import 'components/list';
@import 'components/button';
@import 'components/link';
@import 'components/links';

// Layout.

@import 'layout/main';
@import 'layout/footer';
@import 'layout/wrapper';

@import 'custom';

