@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Type */

	body, input, select, textarea {
    @include var(color, --fg, _palette(fg));
		font-family: _font(family);
		font-size: 14pt;
		font-weight: _font(weight);
		line-height: 2;
		letter-spacing: _size(letter-spacing);
		text-transform: uppercase;

		@include breakpoint(xlarge) {
			font-size: 11pt;
		}

		@include breakpoint(xsmall) {
			font-size: 10pt;
			line-height: 1.75;
		}
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease',
			'border-color #{_duration(transition)} ease'
		));
		color: inherit;
		text-decoration: none;

		&:before {
			@include vendor('transition', (
				'color #{_duration(transition)} ease',
				'text-shadow #{_duration(transition)} ease'
			));
		}

		&:hover {
      @include var(color, --highlight, _palette(highlight));
		}
	}

	strong, b {
    @include var(color, --fg-bold, _palette(fg-bold));

	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
    @include var(color, --fg-bold, _palette(fg-bold));
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.5) 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h1 {
		font-size: 1.85em;
		letter-spacing: (_size(letter-spacing) * 1.1);
		margin: 0 0 (_size(element-margin) * 0.35) 0;
	}

	h2 {
		font-size: 1.25em;
	}

	h3 {
		font-size: 1em;
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		font-size: 1em;
	}

	h6 {
		font-size: 1em;
	}

	@include breakpoint(xsmall) {
		h1 {
			font-size: 1.65em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	hr {
    border-style: solid;
    border-width: _size(border-width);
    @include var(border-color, --border, _palette(border));
		margin: (_size(element-margin) * 2) 0;
	}
