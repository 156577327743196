@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

	// Ensures page width is always >=320px.
		@include breakpoint(xsmall) {
			html, body {
				min-width: 320px;
			}
		}

	body {

		// Prevents animation/transition "flicker" on page load.
		// Automatically added/removed by js/main.js.
			&.is-loading {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

	}

	html {
		height: 100%;
	}

	body {
		height: 100%;
		background-color: _palette(bg);

    @include vendor('background-image', (
			'url("images/overlay.b2866f8d.png")',
			'linear-gradient(60deg, #{_palette(accent1-transparent)} 5%, #{_palette(accent2-transparent)} )',
			'url("../../images/backgrounds/bg.85d7c44f.jpg")'
    ));
    @include vendor('background-image', (
			'url("images/overlay.b2866f8d.png")',
			'linear-gradient(60deg, var(--accent1-transparent, #{_palette(accent1-transparent)} ) 5%, var(--accent2-transparent, #{_palette(accent2-transparent)} ) )',
			'url("../../images/backgrounds/bg.85d7c44f.jpg")'
    ));

    background-repeat:		  repeat,			   no-repeat,		  	no-repeat;
		background-size:		    100px 100px, 	cover,				  cover;
		background-position:	  top left,		   center center,  		top left;
		background-attachment:	fixed,		    fixed,				  fixed;

    @media(prefers-color-scheme: dark) {
      background-image: url(../../images/backgrounds/bg.85d7c44f.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top left;
      background-attachment: fixed;
    }

		&:after {
			content: '';
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: inherit;
			opacity: 0;
			z-index: 1;


      @include var(background-color, --bg, _palette(bg));


      @include vendor('background-image', (
				'url("images/overlay.b2866f8d.png")',
				'linear-gradient(60deg, #{_palette(accent1-transparent)} 5%, #{_palette(accent2-transparent)} )'
			));
      @include vendor('background-image', (
				'url("images/overlay.b2866f8d.png")',
				'linear-gradient(60deg, var(--accent1-transparent, #{_palette(accent1-transparent)}) 5%, var(--accent2-transparent, #{_palette(accent2-transparent)} ) )'
			));
			background-repeat:		repeat,			no-repeat;
			background-size:		100px 100px,	cover;
			background-position:	top left,		center center;

			@include vendor('transition', 'opacity #{_duration(bg)} ease-out');
		}

		&.is-loading {
			&:after {
				opacity: 1;
			}
		}
	}
