@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		position: relative;
		max-width: 100%;
		min-width: 27em;
		@include padding(4.5em, 3em);

    @include var(background, --bg, _palette(bg));
    border-radius: _size(border-radius);
    border-style: solid;
    border-width: 1px;
    @include var(border-color, --border, _palette(border));
		cursor: default;

    opacity: 0.95;
    @media(prefers-color-scheme: dark) {
      border-width: 2px;
    }

		text-align: center;

		@include vendor('transform-origin', '50% 50%');
		@include vendor('transform', 'rotateX(0deg)');
		@include vendor('transition', (
			'opacity #{_duration(main)} ease',
			'transform #{_duration(main)} ease'
		));

		.avatar {
			position: relative;
			display: block;
			margin-bottom: _size(element-margin);

			img {
				display: block;
				margin: 0 auto;
				border-radius: 100%;
        @include fallback(box-shadow, 0 0 0 1.5em var(--bg, _palette(bg)), 0 0 0 1.5em _palette(bg));
				max-width: 128px;
   			max-height: 128px;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: -3em;
				width: calc(100% + 6em);
				height: _size(border-width);
				z-index: -1;
        @include var(background, --border, _palette(border));
      }

      @media(prefers-color-scheme: dark) {
        &:before {
          display: none;
        }
      }
		}

		@include breakpoint(xsmall) {
			min-width: 0;
			width: 100%;
			@include padding(4em, 2em);

			.avatar {
				&:before {
					left: -2em;
					width: calc(100% + 4em);
				}
			}
		}

		body.is-loading & {
			opacity: 0;
			@include vendor('transform', 'rotateX(15deg)');
		}
	}
